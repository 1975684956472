import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SbtScreen, ScreenLink, VerticalScreen } from '../../models/data';
import { AnimationService } from '../../services/animation.service';
import { EventService } from '../../services/event.service';
import { LoggerService } from '../../services/logger.service';
import { StorageService } from '../../services/storage.service';
import { DomHelper } from '../../helpers/dom.helper';
import { SwiperComponent } from 'ngx-useful-swiper';

enum ContentOverflow {
  NO_NEED = 0,
  HIDDEN,
  VISIBLE
}

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
  animations: [
    trigger('introText', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 2500ms linear', keyframes([
          style({ opacity: 0, transform: 'translateY(100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
        ]))
      ]),
      transition(':leave', [
        animate('1s linear', keyframes([
          style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
          style({ opacity: 0, transform: 'translateY(-20%)', offset: 0.2 }),
          style({ opacity: 0, transform: 'translateY(-100%)', offset: 1 })
        ]))
      ])
    ]),
    trigger('buttons', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 })),
      ])
    ]),
  ]
})
export class ScreenComponent implements OnInit {

  @ViewChild('swiperAuto', {static: false}) swiperAuto: SwiperComponent;

  @Input() screen: VerticalScreen;
  public contentId: string;
  public readonly OVERFLOW = ContentOverflow;
  public contentOverflow = ContentOverflow.NO_NEED;
  public loaded: boolean;
  public translateEnd: boolean;
  public hasAnim: boolean;
  public displayButton: boolean;

  private swipeUpSub: Subscription;
  public swipeUp: boolean;
  public swiperCarouselsConfig: SwiperOptions = {
    initialSlide: 0,
    loop: false,
    resistance : true,
    resistanceRatio : 0,
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1000,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };

  public swiperAutoConfig: SwiperOptions = {
    initialSlide: 0,
    loop: true,
    autoplay: {
      delay: 2000, // Below this value, it doesn't work... ?
    },
    slidesPerView: 2,
    spaceBetween: 30
  };

  constructor(
    private animation: AnimationService,
    private logger: LoggerService,
    private event: EventService,
    private storage: StorageService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.contentId = `content-${this.screen.slug}`;
    this.hasAnim = true;
    const intro = this.screen.intro || '';
    setTimeout(() => {
      this.checkOverFlow();
      this.translateEnd = true;
      this.loaded = true;
      const time = (intro.length * 6670 / 100) + 500;
      let normalized = Math.max(2000, time) + 1000;
      if (!this.screen.intro) {
        normalized = 0;
      }
      setTimeout(() => {
        this.hasAnim = false;
        this.displayButton = true;
      }, normalized);
    }, 2000);
  }

  toggleContentOverflow() {
    switch (this.contentOverflow) {
      case ContentOverflow.HIDDEN:
        this.displayButton = false;
        this.contentOverflow = ContentOverflow.VISIBLE;
        this.animation.setFullScreenMode();
        break;
      case ContentOverflow.VISIBLE:
        this.displayButton = true;
        this.contentOverflow = ContentOverflow.HIDDEN;
        this.animation.setInnerScreenMode();
        break;
      default: break;
    }
  }

  checkOverFlow() {
    setTimeout(() => {
      if (!DomHelper.checkDocumentAvailable()) { return; }
      const element = document.querySelector(`#${this.contentId}`);
      if (!element) {
        this.logger.error(`Can't find element with id ${this.contentId}`);
        return;
      }
      const inner = element.querySelector('.screen__body');
      if (!DomHelper.isOverflownY(element, inner)) { return; }
      this.contentOverflow = ContentOverflow.HIDDEN;
    }, 100);
  }

  showButtons() {
    this.swipeUp = true;
    this.unsubscribeSwipeUp();
    this.swipeUpSub = timer(3000).subscribe(() => {
      this.swipeUp = false;
      this.unsubscribeSwipeUp();
    });
  }

  unsubscribeSwipeUp() {
    if (this.swipeUpSub) {
      this.swipeUpSub.unsubscribe();
    }
  }

  toggleBookmark() {
    this.screen.bookmarked = true; // !this.screen.bookmarked;
    // const bookmark = SbtScreen.toBookmark(this.screen);
    const url = window.location.href;

    // Try to open share function
    if (typeof  window.navigator['share'] === 'function') {
      window.navigator['share']({
        title: this.screen.title,
        text: this.screen.rawText,
        url: window.location.href,
      });
    }

    // Try to copy in clipboard
    navigator.clipboard.writeText(url).then(() => {
      this.translate.get('COMMON.COPIED_TO_CLIPBOARD').toPromise().then(message => {
        this.event.openToast(message);
      });
    }, error => {
      this.logger.error('Copy to clipboard ko', error);
    });

    /*if (this.screen.bookmarked) {
      this.storage.addOneBookmark(bookmark);
    } else {
      this.storage.removeOneBookmark(bookmark);
    }*/
  }

  /**
   * 2. Send event to animation service
   * @param event DOM click event
   * @param link button clicked
   */
  clickOnLink(event: Event, link: ScreenLink) {
    this.swipeUp = false;
    this.displayButton = false; // Hide buttons
    setTimeout(() => {
      this.displayButton = true; // Show buttons when scrool back
    }, 2000);
    this.animation.clickOnLink(event.target as Element, link); // 2.
  }
}
