import { animate, animateChild, group, keyframes, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { Bookmark } from '../../models/data';
import { LoggerService } from '../../services/logger.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
  animations: [
    trigger('slideOutParent', [
      transition(':leave', [
        group([
          query('@slideOutChild', animateChild()),
          query('@slideOutTrash', animateChild()),
          animate('1s', keyframes([
            style({ height: '140px', offset: 0 }),
            style({ height: '140px', offset: 0.4 }),
            style({ height: '0', offset: 0.9 }),
          ]))
        ]),
      ])
    ]),
    trigger('slideOutChild', [
      transition(':leave', [
        animate('1s', keyframes([
          style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
          style({ opacity: 0, height: '120px', transform: 'translateX(-200%)', offset: 0.4 }),
          style({ opacity: 0, height: '0', transform: 'translateX(-200%)', offset: 0.9 }),
        ]))
      ])
    ]),
    trigger('slideOutTrash', [
      transition(':leave', [
        animate('1s', keyframes([
          style({ opacity: 0, offset: 0 }),
          style({ opacity: 1, transform: 'scale(1)', offset: 0.1 }),
          style({ opacity: 0, transform: 'scale(2)', offset: 0.4 }),
          style({ opacity: 0, height: '0', offset: 0.6 }),
        ]))
      ])
    ]),
    trigger('popIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ])
    ]),
  ],
})
export class BookmarksComponent implements OnInit {

  public displayButtons = false;
  public bookmarks: Bookmark[] = [];

  constructor(
    private logger: LoggerService,
    private storage: StorageService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.displayButtons = false;
    this.bookmarks = this.storage.getBookmarks().map(mark => {
      mark.background = !mark.background ? null : `url(${mark.background})`;
      mark.text = this.sanitizer.sanitize(SecurityContext.HTML, mark.text);
      mark.date = new Date(mark.date);
      return mark;
    });
    this.logger.log('Bookmarks :', this.bookmarks);
  }

  closeBookmarkList() {}

  removeBookmark(bookmark: Bookmark) {
    this.storage.removeOneBookmark(bookmark);
    this.bookmarks.splice(this.bookmarks.indexOf(bookmark), 1);
  }

  displaySendButtons() {
    this.displayButtons = true;
  }

  sendBookmarks(clear: boolean) {
    if (clear) {
      this.clearList();
    }
  }

  clearList() {
    this.storage.clearBookmarks();
    const length = this.bookmarks.length;
    for (let i = 0; i < length; i++) {
      timer(50 * i).toPromise().then(() => {
        this.bookmarks.shift();
      });
    }
  }
}
