import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RulesComponent } from './components/rules/rules.component';
import { Constants } from './constants';

const routes: Routes = [
  { path: Constants.APP_URLS.EMPTY, pathMatch: 'full', redirectTo: '/navigation/Menu' },
  { path: `${Constants.APP_URLS.HOME}`, component: HomeComponent },
  { path: Constants.APP_URLS.RULES, component: RulesComponent },
  // { path: Constants.APP_URLS.BOOKMARKS, component: BookmarksComponent },
  { path: '**', redirectTo: '',  pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
