import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent {

  constructor(
    private router: Router,
    private logger: LoggerService,
  ) {}

  close() {
    // TODO: get old page
    this.router.navigateByUrl(Constants.APP_URLS.HOME.replace(':term', 'Menu'));
  }
}
