import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ScreenMeta, SbtSequence } from '../models/data';
import { SessionService } from './session.service';

const DEFAULT_META: ScreenMeta = {
  title: 'SBT Human(s) Matter',
  keyword: 'SBT',
  description: 'Développer ses potentialités'
};

@Injectable()
export class SEOService {

  constructor(
    private title: Title,
    private meta: Meta,
    private session: SessionService,
  ) { }

  public setMetaData(data: ScreenMeta) {
    let sequence = this.session.getSequenceValue();
    if (!sequence) { sequence = {} as SbtSequence; }
    const config = (sequence.screens || []).find(x => x.slug === 'config');
    const defMeta = config && config.meta ? config.meta : DEFAULT_META;
    this.updateTitle(data && data.title || defMeta.title);
    this.updateKeyword(data && data.keyword || defMeta.keyword);
    this.updateDescription(data && data.description || defMeta.description);
  }

  private updateTitle(title: string) {
    this.title.setTitle(title);
  }

  private updateKeyword(keyword: string) {
    this.changeTag('keyword', keyword);
  }

  private updateDescription(description: string) {
    this.changeTag('description', description);
  }

  private changeTag(tag: string, value: string) {
    this.meta.updateTag({ name: tag, content: value });
  }
}
