

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { Constants } from '../constants';
import { SbtSequence } from '../models/data';
import { DataService } from './data.service';
import { EventService } from './event.service';
import { LoggerService } from './logger.service';

@Injectable()
export class SessionService {

  private sequence$ = new BehaviorSubject<SbtSequence>(null);

  constructor(
    private translate: TranslateService,
    private logger: LoggerService,
    private data: DataService,
    private event: EventService,
  ) {}

  public initialize() {
    const def = Constants.LANGUAGES.DEFAULT;
    this.translate.setDefaultLang(def);
    this.changeLanguage(def, false);
  }

  public changeLanguage(code: string, forceRefresh = true) {
    const lang = Constants.LANGUAGES.AVAILABLES.find(x => x.code === code);
    if (!lang) {
      this.logger.warn(`Language with code '${code}' not found`);
      return;
    }

    this.event.startLoading();
    this.data.getScreensData(code).then(seq => {
      seq.forceRefresh = forceRefresh;
      this.translate.use(code);
      this.sequence$.next(seq);
    }).finally(() => {
      timer(1500).toPromise().then(() => {
        this.event.stopLoading();
      });
    });
  }

  public getSequence$(): Observable<SbtSequence> {
    return this.sequence$.asObservable();
  }

  public getSequenceValue(): SbtSequence {
    return this.sequence$.getValue();
  }
}
