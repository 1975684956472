import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../../services/event.service';
import { LoggerService } from '../../services/logger.service';
import { trigger, transition, style, animate, keyframes, animateChild, group, query } from '@angular/animations';
import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { SessionService } from '../../services/session.service';

class Language {
  label: string;
  code: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('menu', [
      transition(':enter', [
        group([
          query('@menuBackground', animateChild()),
          query('@menuContent', animateChild()),
        ])
      ]),
      transition(':leave', [
        group([
          query('@menuBackground', animateChild()),
          query('@menuContent', animateChild()),
        ])
      ])
    ]),
    trigger('menuBackground', [
      transition(':enter', [
        animate('1s ease-in-out', keyframes([
          style({ 'border-width': 0, offset: 0 }),
          style({ 'border-width': '300vw', offset: 1 })
        ]))
      ]),
      transition(':leave', [
        animate('1s ease-in-out', keyframes([
          style({ 'border-width': '300vw', offset: 0 }),
          style({ 'border-width': 0, offset: 1 })
        ]))
      ])
    ]),
    trigger('menuContent', [
      transition(':enter', [
        animate('1s', keyframes([
          style({ opacity: 0, offset: 0 }),
          style({ opacity: 0, offset: 0.6 }),
          style({ opacity: 1, offset: 1 })
        ]))
      ]),
      transition(':leave', [
        animate('1s', keyframes([
          style({ opacity: 1, offset: 0 }),
          style({ opacity: 0, offset: 0.2 }),
          style({ opacity: 0, offset: 1 })
        ]))
      ])
    ]),
  ]
})
export class MenuComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  public display: boolean;
  public selected = Constants.LANGUAGES.DEFAULT;
  public languages: Language[] = Constants.LANGUAGES.AVAILABLES;

  constructor(
    private event: EventService,
    private router: Router,
    private session: SessionService,
    private logger: LoggerService,
  ) {}

  ngOnInit() {
    this.sub = this.event.getMenu$().subscribe(opened => {
      this.display = opened;
    });
  }

  goToRules() {
    this.router.navigateByUrl(Constants.APP_URLS.RULES).then(() => {
      this.closeMenu();
    });
  }

  closeMenu() {
    this.event.closeMenu();
  }

  selectLang(lang: Language) {
    this.selected = lang.code;
    this.session.changeLanguage(lang.code);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
