import { Component } from '@angular/core';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  template: `
    <app-orientation></app-orientation>
    <app-loading></app-loading>
    <app-toast></app-toast>
    <app-menu></app-menu>
    <router-outlet></router-outlet>`
})
export class AppComponent {

  constructor(
    private session: SessionService,
  ) {
    this.session.initialize();
  }
}
