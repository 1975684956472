/**
 * Logger level enum
 *
 * Each value will include upper values
 *
 * If "OFF" => nothing will be logged
 */
export enum LoggerLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  OFF = 6,
}
