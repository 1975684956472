import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as marked from 'marked';
import { CarouselItem, DataLinks, HorizontalScreen, SbtSequence, ScreenLink,
  ScreenMeta, TwineData, TwineLink, TwinePassage, VerticalScreen } from '../models/data';
import { Parses } from '../models/parse';
import { AnimationService } from './animation.service';
import { HttpService } from './http.service';
import { LoggerService } from './logger.service';
import { Constants } from '../constants';

@Injectable()
export class DataService extends HttpService {

  constructor(
    protected http: HttpClient,
    protected logger: LoggerService,
    private animation: AnimationService,
    private sanitizer: DomSanitizer,
  ) {
    super(http, logger);
    marked.setOptions({
      headerIds: false,
    });
  }

  private fetchData(language: string): Promise<TwineData> {
    return this.get(`assets/twine/${language}/v29.json`);
  }

  public getScreensData(language: string): Promise<SbtSequence> {
    return this.fetchData(language).then(data => {
      let screens = data.passages.map((passage =>  this.toVerticalScreen(passage)));
      screens = this.matchLinkNameToSlug(screens);
      this.animation.preloadBackgrounds(screens);
      return { startName: data.startnode, screens };
    });
  }

  public matchLinkNameToSlug(screens: VerticalScreen[]): VerticalScreen[] {
    return screens.map(vs => {
      if (vs.links) {
        vs.links.forEach(l => {
          l.slug = this.matchScreenSlugWithName(screens, l);
        });
      }
      if (vs.carousels) {
        vs.carousels.forEach(sh => {
          if (sh.links) {
            sh.links.forEach(l => {
              l.slug = this.matchScreenSlugWithName(screens, l);
            });
          }
        });
      }
      return vs;
    });
  }

  private matchScreenSlugWithName(screens: VerticalScreen[], button: ScreenLink): string {
    if (button.isExternal) { return null; }
    const associated = screens.find(s => s.name === button.link);
    if (!associated) {
      this.logger.error(`Screen with name '${button.link}' is unknown, can't find associated slug`);
      return Constants.VARIABLES.UNKNOWN_LINK;
    }
    return associated.slug;
  }

  public toVerticalScreen(passage: TwinePassage): VerticalScreen {

    let text = passage.text;
    const meta = {} as ScreenMeta;
    let slug, backPortrait, backLandscape, carouselRaw, slider, title, links;

    [text, carouselRaw] = this.findFirstNode(Parses.TAGS_NAME.CAROUSEL, text);
    [text, slider] = this.findFirstNode(Parses.TAGS_NAME.CAROUSEL_AUTO, text);

    const carousels = this.findHorizontalScreens(carouselRaw, passage.links, passage.name);
    const sliders = this.findAllItems(slider, passage.links, true);

    [text, backLandscape] = this.matchFirstNode(Parses.TAGS_NAME.BACKGROUND_LANDSCAPE, text);
    [text, backPortrait] = this.matchFirstNode(Parses.TAGS_NAME.BACKGROUND_PORTRAIT, text);
    [text, meta.title] = this.matchFirstNode(Parses.TAGS_NAME.META_TITLE, text);
    [text, meta.keyword] = this.matchFirstNode(Parses.TAGS_NAME.META_KEYWORD, text);
    [text, meta.description] = this.matchFirstNode(Parses.TAGS_NAME.META_DESCRIPTION, text);
    [text, slug] = this.matchFirstNode(Parses.TAGS_NAME.META_SLUG, text);

    if (!slug) {
      slug = this.normalizeString(passage.name);
      const isCarousel = carousels ? '(CAROUSEL)' : '';
      this.logger.warn(`No slug for page ${isCarousel} '${passage.name}', using '${slug}'`);
    }

    text = this.replaceInnerLinks(text);

    const buttons = this.findAllLinks(text, passage.links);
    text = buttons.text;
    links = buttons.links;

    text = this.mdTextToHtmlText(text, !carousels, passage.pid);

    text = this.quickSanitizingReverse(text);

    text = this.manageHashtags(text);

    [text, title] = this.matchFirstNode(Parses.TAGS_NAME.TITLE, text);

    if (carousels && carousels.length > 0) {
      title = carousels[0].title; // FIXME: sale (pour la transition du titre...)

      carousels.forEach(element => {
        element.links = element.links ? element.links.concat(links) : links;
      });
    }

    text = this.removeComments(text);

    text = this.removeFirstBr(text);

    const rawText = text;
    text = this.sanitizer.bypassSecurityTrustHtml(text) as string;
    return new VerticalScreen({
      pid: passage.pid,
      slug,
      name: passage.name,
      text,
      rawText,
      background: this.getBackground(backPortrait, backLandscape),
      backgroundLandscape: backLandscape,
      backgroundPortrait: backPortrait,
      links,
      title,
      carousels,
      carouselAuto: sliders,
      meta
    });
  }

  private getBackground(portrait: string, landscape: string) {
    if (this.animation.isPortraitDevice()) {
      return portrait;
    } else {
      return landscape;
    }
  }

  private removeComments(text: string): string {
    if (!text) { return text; }
    const startTag = '<!--';
    const endTag = '-->';
    const startIndex = text.indexOf(startTag);
    if (startIndex < 0) {
      return text;
    }

    let endIndex = text.indexOf(endTag);
    if (endIndex < 0) {
      endIndex = text.length;
      this.logger.warn(`Comment in content without end tag`);
    }

    return text.substring(0, startIndex) + text.substring(endIndex + endTag.length);
  }

  private removeFirstBr(text: string): string {
    if (!text) { return text; }
    const rm = '<br/>';
    while (text.startsWith(rm)) {
      text = text.substring(rm.length);
    }
    return text;
  }

  private normalizeString(link: string): string {
    return link
            .trim()
            .replace(/#/g, '')
            .replace(/&/g, '')
            .replace(/·/g, '')
            .replace(/ /g, '-')
            .replace(/'/g, '')
            .toLowerCase();
  }

  private findHorizontalScreens(text: string, links: TwineLink[], parentName: string): HorizontalScreen[] {
    const screens = this.findAllItems(text, links, false);
    return !screens ? null : screens.map((car, i) => {
      let cText = car.text, cTitle, cBackLand, cBackPort, cSlug;
      [cText, cBackLand] = this.matchFirstNode(Parses.TAGS_NAME.BACKGROUND_LANDSCAPE, cText);
      [cText, cBackPort] = this.matchFirstNode(Parses.TAGS_NAME.BACKGROUND_PORTRAIT, cText);
      [cText, cSlug] = this.matchFirstNode(Parses.TAGS_NAME.META_SLUG, cText);
      if (!cSlug) {
        cSlug = this.normalizeString(`${parentName}-car-${i}`);
        this.logger.warn(`No slug for item ${i} of screen '${parentName}', using '${cSlug}'`);
      }

      cText = this.mdTextToHtmlText(cText, true);
      [cText, cTitle] = this.matchFirstNode('<h2>', cText);
      const rawText = cText;
      cText = this.sanitizer.bypassSecurityTrustHtml(cText) as string;
      return new HorizontalScreen({
        background: this.getBackground(cBackPort, cBackLand),
        backgroundLandscape: cBackLand,
        backgroundPortrait: cBackPort,
        bookmarked: false, // TODO:
        slug: cSlug,
        name: car.id, // TODO: n'existe plus, utiliser le slug ?
        text: cText,
        rawText,
        title: cTitle,
        links: car.links,
        isInCarousel: true,
      });
    });
  }

  private manageHashtags(text: string) {
    if (!text) { return null; }
    let index = -1;
    do {
      index = text.indexOf('.#');
      if (index > -1) {
        text = text.substr(0, index) + text.substring(index + 1);
      }
    } while (index >= 0);
    return text;
  }

  private quickSanitizingReverse(text: string) {
    if (!text) { return null; }

    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;'
    };

    Object.keys(entityMap).forEach(key => {
      text = text.replace(new RegExp(entityMap[key], 'g'), key);
    });
    return text;
  }

  private mdTextToHtmlText(text: string, shouldHaveData: boolean, passageId?: string) {
    if (!text) {
      if (shouldHaveData) {
        this.logger.warn('No content for a screen or a carousel item', passageId);
      }
      return text;
    }

    text = marked(text);
    text = text.split('\n').join('<br/>');
    return text;
  }

  private matchFirstNode(tag: string, text: string): string[] {
    if (!text) { return [null, null]; }

    const matching = text.match(`${tag}(.*)${this.getEndTag(tag)}`);
    if (!matching) { return [text, null]; }
    return [
      text.substr(0, matching.index) + text.substr(matching.index + matching[0].length),
      matching[1]
    ];
  }

  private getEndTag(tag: string) {
    return `${tag.substr(0, 1)}/${tag.substr(1)}`;
  }

  private findFirstNodeWithId(startTag: string, endTag: string, text: string): { all: string, node: string, id: string } {
    if (!text) { return { all: null, node: null, id: null }; }

    const startIndex = text.indexOf(startTag);
    if (startIndex < 0) {
      return { all: text, node: null, id: null };
    }

    const endStartTag = text.indexOf('>', startIndex);
    const idContainer = text.substring(startIndex, endStartTag);
    let id = null;
    if (idContainer.includes('id=')) {
      id = idContainer.substring(idContainer.indexOf('"'), idContainer.lastIndexOf('"'));
    }

    let endIndex = text.indexOf(endTag);
    if (endIndex < 0) {
      endIndex = text.length;
      this.logger.warn(`Data from screen without ${endTag} end tag`);
    }

    return {
      all: text.substring(0, startIndex) + text.substring(endIndex + endTag.length),
      node: text.substring(endStartTag, endIndex),
      id
    };
  }

  private findFirstNode(tag: string, text: string): string[] {
    if (!text) { return [null, null]; }

    const startTag = tag;
    const endTag = this.getEndTag(tag);
    const startIndex = text.indexOf(startTag);
    if (startIndex < 0) {
      return [text, null];
    }

    let endIndex = text.indexOf(endTag);
    if (endIndex < 0) {
      endIndex = text.length;
      this.logger.warn(`Data from screen without ${tag} end tag`);
    }

    return [
      text.substring(0, startIndex) + text.substring(endIndex + endTag.length),
      text.substring(startIndex + startTag.length, endIndex)
    ];
  }

  private findFirstButton(text: string): string[] {
    if (!text) { return [null, null, null]; }

    const startTag = '[[';
    const endTag = ']]';
    const startTransiTag = '<transition>';
    const endTransiTag = '</transition>';
    const startIndex = text.indexOf(startTag);
    if (startIndex < 0) {
      return [text, null, null];
    }

    let endIndex = text.indexOf(endTag);
    if (endIndex < 0) {
      endIndex = text.length;
      this.logger.warn(`Data from screen without ${startTag} end tag`);
    }

    let transition = null;
    const button = text.substring(startIndex + startTag.length, endIndex);

    const startTransition = text.indexOf(startTransiTag, endIndex);
    if (startTransition > 0) {
      let endTransition = text.indexOf(endTransiTag, startTransition);
      if (endTransition < 0) {
        endTransition = startTransition;
        this.logger.warn(`Button transition without end tag`);
      }
      transition = text.substring(startTransition + startTransiTag.length, endTransition);
      endIndex = endTransition + endTransiTag.length;
    } else {
      endIndex = endIndex + endTag.length;
    }

    const remaining = text.substring(0, startIndex) + text.substring(endIndex);

    return [ remaining, button, transition ];
  }

  // TODO: nettoyer cette fonction de folie
  private findAllItems(carousel: string, existingLinks: TwineLink[], quickfix: boolean): CarouselItem[] {

    if (!carousel) {
      return null;
    }

    let content: string;
    const items: CarouselItem[] = [];

    do {
      const find = this.findFirstNodeWithId('<ITEM', '</ITEM>', carousel);
      carousel = find.all;
      content = find.node;

      if (content) {
        const item = this.findAllLinks(content, existingLinks);
        if (quickfix) {
          item.text = this.mdTextToHtmlText(item.text, true);
        }
        items.push({ id: find.id, text: item.text, links: item.links });
      }
    } while (content != null);

    carousel = carousel.trim();
    if (carousel.length > 0) {
      this.logger.warn('More data in carousel', carousel);
    }

    return items;
  }

  private replaceInnerLinks(text: string) {
    if (!text) { return null; }
    const startTag = '[[§ ';
    const endTag = ']]';
    const middleTag = '->';
    while (text.includes(startTag)) {
      const start = text.indexOf(startTag);
      if (start > -1) {
        const end = text.indexOf(endTag, start);
        const [btnText, btnLink] = text.substring(start + startTag.length, end).split(middleTag);
        text = text.substring(0, start) +
          this.getWhiteButtonTemplate(btnText, btnLink) +
          text.substr(end + endTag.length);
      }
    }
    return text;
  }

  private getWhiteButtonTemplate(text: string, link: string) {
    // tslint:disable-next-line: max-line-length
    // TODO: link ou slug en fonction d'un lien externe ou interne
    // TODO: review
    return `<a class="sbt-white-button" routerLink="'/navigation/${link}'" role="button" (click)="onClick($event)">${text}</a>`;
  }

  private findAllLinks(text: string, existingLinks: TwineLink[]): DataLinks {

    let content, transition: string;
    const links: ScreenLink[] = [];
    do {
      [text, content, transition] = this.findFirstButton(text);
      if (content) {
        const button = this.matchLink(content, existingLinks);
        button.transition = transition;
        links.push(button);
      }
    } while (content != null);

    return { text, links };
  }

  private matchLink(raw: string, existingLinks: TwineLink[]): ScreenLink {
    const rawLink = raw.split('->')[1];
    const link = existingLinks.find(x => x.link === rawLink);
    if (!link) {
      this.logger.warn('No link found for', raw);
    }
    return TwineLink.toScreenLink(link);
  }
}
