import { Injectable } from '@angular/core';
import { Bookmark } from '../models/data';
import { LoggerService } from './logger.service';

const BOOKMARK_PATHS = 'bookmarks';

@Injectable()
export class StorageService {

  constructor(
    private logger: LoggerService,
  ) {
    if (this.checkLocalStorageUnavailable()) {
      this.logger.warn('Local storage NOT available');
    }
  }

  public getBookmarks(): Bookmark[] {
    return this.getItem(BOOKMARK_PATHS) || [];
  }

  public removeOneBookmark(bookmark: Bookmark) {
    const all = this.getBookmarks().filter(b => b.slug !== bookmark.slug);
    this.setBookmarks(all);
  }

  public clearBookmarks() {
    this.clearItem(BOOKMARK_PATHS);
  }

  public addOneBookmark(bookmark: Bookmark) {
    const all = this.getBookmarks();
    if (all.find(x => x.slug === bookmark.slug)) {
      this.logger.warn('Bookmark already in storage');
      return;
    }
    all.push(bookmark);
    this.setBookmarks(all);
  }

  private setBookmarks(bookmarks: Bookmark[]) {
    return this.setItem(BOOKMARK_PATHS, bookmarks);
  }

  private getItem(key: string): any {
    if (this.checkLocalStorageUnavailable()) {
      this.logger.warn('Local storage NOT available, cant getItem');
      return null;
    }

    return JSON.parse(localStorage.getItem(key));
  }

  private setItem(key: string, obj: object) {
    if (this.checkLocalStorageUnavailable()) {
      this.logger.warn('Local storage NOT available, cant setItem');
      return;
    }

    localStorage.setItem(key, JSON.stringify(obj));
  }

  private clearItem(key: string) {
    if (this.checkLocalStorageUnavailable()) {
      this.logger.warn('Local storage NOT available, cant clearItem');
      return;
    }

    localStorage.removeItem(key);
  }

  private checkLocalStorageUnavailable() {
    return typeof window === 'undefined' || typeof window.localStorage === 'undefined';
  }
}
