const APP_URLS = {
  EMPTY: '',
  HOME: 'navigation/:term',
  BOOKMARKS: 'bookmarks',
  RULES: 'legal-notice'
};

const VARIABLES = {
  DEFAULT_BACKGROUND: 'assets/images/background.png',
  DEFAULT_SCREEN_NAME: 'Menu',
  UNKNOWN_LINK: 'UNKNOWN'
};

const LANGUAGES = {
  DEFAULT: 'fr',
  AVAILABLES: [
    { label: 'FR', code: 'fr' },
    { label: 'EN', code: 'en' },
  ]
};

export const Constants = {
  APP_URLS,
  VARIABLES,
  LANGUAGES,
};
