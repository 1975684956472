import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoggerLevel } from '../models/logger';

/**
 * Service used to manage logs in the application
 */
@Injectable()
export class LoggerService {

  // tslint:disable no-console

  constructor() { }

  /**
   * Print in console a log
   * @param level log level
   * @param printFunction print function (trace, debug, info, log, warn, error)
   * @param message string message
   * @param additionals additional messages
   */
  private print(level: LoggerLevel, printFunction, message, ...additionals: any[]) {
    if (environment.logLevel > level) { return; }
    if (additionals.length > 0) {
      printFunction(message, ...additionals);
    } else {
      printFunction(message);
    }
  }

  /**
   * Print trace log
   */
  public trace(message, ...additionals: any[]) {
    this.print(LoggerLevel.TRACE, console.trace, message, ...additionals);
  }

  /**
   * Print debug log
   */
  public debug(message, ...additionals: any[]) {
    this.print(LoggerLevel.DEBUG, console.debug, message, ...additionals);
  }

  /**
   * Print info log
   */
  public info(message, ...additionals: any[]) {
    this.print(LoggerLevel.INFO, console.info, message, ...additionals);
  }

  /**
   * Print log log
   */
  public log(message, ...additionals: any[]) {
    this.print(LoggerLevel.LOG, console.log, message, ...additionals);
  }

  /**
   * Print warn log
   */
  public warn(message, ...additionals: any[]) {
    this.print(LoggerLevel.WARN, console.warn, message, ...additionals);
  }

  /**
   * Print error log
   */
  public error(message, ...additionals: any[]) {
    this.print(LoggerLevel.ERROR, console.error, message, ...additionals);
  }
}
