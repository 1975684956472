import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
export class HttpService {

  constructor(
    protected http: HttpClient,
    protected logger: LoggerService
  ) { }

  protected get(url): Promise<any> {
    return this.http.get(url).toPromise();
  }

  protected post(url, object): Promise<any> {
    return this.http.post(url, object).toPromise();
  }

  protected put(url, object): Promise<any> {
    return this.http.put(url, object).toPromise();
  }

  protected patch(url, object): Promise<any> {
    return this.http.patch(url, object).toPromise();
  }

  protected delete(url): Promise<any> {
    return this.http.delete(url).toPromise();
  }
}
