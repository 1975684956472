export class StringHelper {

  static getHtmlRawText(text: string): string {
    return (text || '').replace(/<[^>]*>/g, '');
  }

  static getTruncatedText(text: string, length: number): string {
    text = (text || '');
    text = text.substr(0, Math.min(length, text.length));
    if (text.length >= 200) { text += '...'; }
    return text;
  }

  static getBookmarkText(text: string): string {
    return StringHelper.getTruncatedText(
      StringHelper.getHtmlRawText(text),
      200
    );
  }
}
