import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BackgroundTransition, LinkClicked } from '../models/animation';
import { VerticalScreen, ScreenLink, ScreenLinkType } from '../models/data';
import { LoggerService } from './logger.service';
import { Constants } from '../constants';
import { DomHelper } from '../helpers/dom.helper';

const DEFAULT_BACKGROUND_URL = Constants.VARIABLES.DEFAULT_BACKGROUND;
const DEFAULT_TRANSITION: BackgroundTransition = {
  background: DEFAULT_BACKGROUND_URL,
  automatic: true
};

@Injectable()
export class AnimationService {

  private linkClicked$ = new Subject<LinkClicked>();
  private fullScreenMode$ = new Subject<boolean>();
  private background$ = new BehaviorSubject<BackgroundTransition>(DEFAULT_TRANSITION);

  constructor(
    private logger: LoggerService,
  ) {}

  public setBackground(background: string, automatic = true) {
    if (!background) { background = DEFAULT_BACKGROUND_URL; }
    this.background$.next({ background, automatic });
  }
  public getBackground$(): Observable<BackgroundTransition> { return this.background$.asObservable(); }
  public getBackgroundValue(): BackgroundTransition { return this.background$.getValue(); }

  public clickOnLink(domElement: Element, button: ScreenLink) { this.linkClicked$.next({ domElement, button }); }
  public getClickedLink$(): Observable<LinkClicked> { return this.linkClicked$.asObservable(); }

  public setFullScreenMode() { this.fullScreenMode$.next(true); }
  public setInnerScreenMode() { this.fullScreenMode$.next(false); }
  public getFullScreenMode$(): Observable<boolean> { return this.fullScreenMode$.asObservable(); }

  isPortraitDevice(): boolean {
    if (!DomHelper.checkDocumentAvailable()) { return false; }
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 600;
  }

  animateButtonToTitle(elem: Element, button: ScreenLink, next: VerticalScreen) {
    if (!DomHelper.checkDocumentAvailable()) { return; }

    const elemBounds = elem.getBoundingClientRect();
    const elemText = elem.innerHTML;

    const text = document.createElement('div');
    text.className = 'free-text';
    text.innerHTML = elemText;
    text.style.top = `${elemBounds.top + 8}px`;
    text.style.left = `${elemBounds.left + 42}px`;

    const square = document.createElement('div');
    square.className = `free-square icon icon-sm icon-${ScreenLinkType[button.type]}`;
    square.style.top = `${elemBounds.top + 17}px`;
    square.style.left = `${elemBounds.left + 17}px`;

    const x = Math.max(0, (document.body.clientWidth - 700) / 2);
    const squareX = x + 35; // ( 40 - 12/2 + 2/2)
    const textX = x + (next.carousels && document.body.clientWidth < 600 ? 70 : 110);

    // TODO: Si de carousel à carousel => pas de picto
    // Faire disparaitre le picto en fondu
    // Faire disparaitre le picto en meme temps que le fil

    // TODO: clear tous ces timeouts
    setTimeout(() => {
      square.style.left = `${squareX}px`;
      text.style.left = `${textX}px`;
      setTimeout(() => {
        text.classList.add('vertical-translate');
        square.classList.add('vertical-translate');

        setTimeout(() => {
          text.innerHTML = next.title;
          if (next.carousels) {
            square.className = '';
          }
        }, 200);

        setTimeout(() => {
          document.body.removeChild(square);
          document.body.removeChild(text);
        }, 1100);
      }, 1000);
    }, 10);

    document.body.appendChild(square);
    document.body.appendChild(text);
  }

  public preloadBackgrounds(screens: VerticalScreen[]) {
    if (!DomHelper.checkDocumentAvailable()) { return; }

    screens.map(screen => {
      const array = [];
      if (screen.background) {
        array.push(screen.background);
      }
      if (screen.carousels) {
        screen.carousels.forEach(item => {
          if (screen.background) {
            array.push(item.background);
          }
        });
      }
      return array;
    })
    .reduce((a, c) => a.concat(c), [])
    .forEach(url => {
      if (!url) { return; }
      const i = document.createElement('img');
      i.src = url;
      i.style.position = 'absolute';
      i.style.width = '100%';
      i.style.height = '100%';
      i.style.top = '-800%';
      i.style.left = '-800%';
      i.onload = () => {
        setTimeout(() => { document.body.removeChild(i); }, 500);
      };
      document.body.appendChild(i);
    });
  }
}
