import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { LinkClicked } from '../../models/animation';
import { ScreenLink, ScreenLinkType, VerticalScreen, SbtSequence } from '../../models/data';
import { AnimationService } from '../../services/animation.service';
import { EventService } from '../../services/event.service';
import { LoggerService } from '../../services/logger.service';
import { SEOService } from '../../services/seo.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('guideline', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  @ViewChild('mainSwiper', {static: false}) mainSwiper: SwiperComponent;

  private routerSub: Subscription;
  private linkSub: Subscription;
  private seqSub: Subscription;
  private screenSub: Subscription;

  public allScreens: VerticalScreen[];
  public current: VerticalScreen;
  public navScreens: VerticalScreen[];

  public swiperConfig: SwiperOptions = {
    initialSlide: 0,
    loop: false,
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1000,
    followFinger: false,
    mousewheel: true,
    resistance : true,
    resistanceRatio : 0,
    preventInteractionOnTransition: true,
    allowSlideNext: false,
    watchSlidesProgress: true,
    on: {
      slideChangeTransitionStart: () => {},
      transitionEnd: () => { },
      slidePrevTransitionStart: () => {
        window.history.back();
        this.updatePrevScreen();
      },
      slidePrevTransitionEnd: () => {
        const swiper = this.mainSwiper.swiper;
        this.navScreens.pop();
        swiper.update();
      }
    }
  };

  constructor(
    private event: EventService,
    private route: ActivatedRoute,
    private animation: AnimationService,
    private session: SessionService,
    private logger: LoggerService,
    private seo: SEOService,
  ) { }

  ngOnInit() {
    this.navScreens = [];
    this.subscribeToSequence();
    this.subscribeToLinkClick();
    this.subscribeScreenMode();
  }

  private subscribeScreenMode() {
    this.screenSub = this.animation.getFullScreenMode$().subscribe(isFullScreen => {
      if (isFullScreen) {
        this.mainSwiper.swiper.mousewheel.disable();
      } else {
        this.mainSwiper.swiper.mousewheel.enable();
      }
    });
  }

  private subscribeToSequence() {
    this.seqSub = this.session.getSequence$().subscribe(sequence => {
      if (!sequence) { return; }
      this.initSequence(sequence);
    });
  }

  private subscribeToLinkClick() {
    this.linkSub = this.animation.getClickedLink$().subscribe((click) => {
      this.slideNext(click);
    });
  }

  private initSequence(sequence: SbtSequence) {
    this.navScreens = [];
    this.allScreens = sequence.screens;
    this.initFirstScreen(sequence.startName, sequence.forceRefresh);
  }

  private initFirstScreen(start: string, forceRefresh = false) {
    let screen = this.allScreens.find(x => x.pid === start);
    const url = this.route.snapshot && this.route.snapshot.params && this.route.snapshot.params.term;
    if (url !== screen.name && !forceRefresh) {
      // TODO: menu as first page when scroll back
      this.logger.warn('Not starting from menu');
      screen = this.allScreens.find(x => x.slug === url);
    }

    // TODO: merge with goNextScreen
    screen.picto = ScreenLinkType[ScreenLinkType.square];
    this.animation.setBackground(screen.background);
    this.navScreens.push(screen);
    this.seo.setMetaData(screen.meta);
    this.logger.log('First screen', screen);
    this.current = screen;

    // In case of language change in menu => reload to first page
    if (this.mainSwiper && this.mainSwiper.swiper) {
      this.mainSwiper.swiper.update();
      this.mainSwiper.swiper.slideTo(0);
    }
  }

  ngOnDestroy() {
    if (this.routerSub) { this.routerSub.unsubscribe(); }
    if (this.linkSub) { this.linkSub.unsubscribe(); }
    if (this.seqSub) { this.seqSub.unsubscribe(); }
    if (this.screenSub) { this.screenSub.unsubscribe(); }
  }

  /*listenRouter() {
    this.routerSub = this.route.params.subscribe(params => {
      const pageLink = params.term;
      this.current = this.allScreens.find(x => x.name === pageLink);
    });
  }*/

  updatePrevScreen() {
    this.current = this.navScreens[this.navScreens.length - 2];
    this.animation.setBackground(this.current.background);
    this.seo.setMetaData(this.current.meta);
  }

  getNextScreen(button: ScreenLink): VerticalScreen {
    const next = this.allScreens.find(x => x.slug === button.slug);
    if (!next) {
      this.logger.error(`No screen matching with slug : ${button.slug}`);
      return;
    }

    this.logger.log('Next screen', next);
    this.animation.setBackground(next.background);
    next.picto = ScreenLinkType[button.type];
    next.intro = button.transition;
    this.navScreens.push(next);
    this.seo.setMetaData(next.meta);
    this.current = next;
    return next;
  }

  slideNext(click: LinkClicked) {
    const next = this.getNextScreen(click.button);
    this.animation.animateButtonToTitle(click.domElement, click.button, next);
    this.mainSwiper.swiper.update();
    setTimeout(() => {
      this.mainSwiper.swiper.allowSlideNext = true;
      this.mainSwiper.swiper.slideNext();
      this.mainSwiper.swiper.allowSlideNext = false;
    }, 100);
  }

  openMenu() {
    this.event.openMenu();
  }
}
