import { StringHelper } from '../helpers/string.helper';
import { Constants } from '../constants';

export class TwineData {
  ifid: string;
  name: string;
  creator: string;
  'creator-version': string;
  startnode: string;
  passages: TwinePassage[];
}

export class TwineLink {
  pid?: string;
  name: string;
  link: string;

  static toScreenLink(button: TwineLink) {
    return new ScreenLink({
      slug: Constants.VARIABLES.UNKNOWN_LINK,
      pid: button.pid,
      link: button.link,
      text: !button.name ? null : button.name.substr(2),
      isExternal: (button.link || '').startsWith('http'),
      type: !button.name ? null : TwineLink.parseLinkSignsToTypes(button.name.slice(0, 1))
    });
  }

  static parseLinkSignsToTypes(sign: string) {
    switch (sign) {
      case '%': return ScreenLinkType.square;
      case 'o': return ScreenLinkType.disk;
      case '>': return ScreenLinkType.triangle;
      case '+': return ScreenLinkType.plus;
      case 'x': return ScreenLinkType.times;
      case '§':
      default:
        return ScreenLinkType.empty;
    }
  }
}

export class Position {
  x: string;
  y: string;
}

export class TwinePassage {
  pid: string;
  name: string; // Link of the screen
  text: string;
  position: Position;
  links: TwineLink[];
  tags: string[];
}

export class SbtSequence {
  forceRefresh?: boolean;
  startName: string;
  screens: VerticalScreen[];
}

export class SbtScreen {
  slug: string;
  name: string; // Kind of link // TODO: rename to link ?
  text: string;
  rawText: string;
  background?: string;
  backgroundPortrait?: string;
  backgroundLandscape?: string;
  title?: string;
  links?: ScreenLink[];
  bookmarked?: boolean;
  isInCarousel?: boolean;

  static toBookmark(screen: SbtScreen) {
    return new Bookmark({
      slug: screen.slug,
      title: screen.title,
      name: screen.name,
      background: screen.backgroundPortrait,
      date: new Date(),
      text: StringHelper.getBookmarkText(screen.rawText)
    });
  }
}

export class HorizontalScreen extends SbtScreen {
  constructor(obj: HorizontalScreen) {
    super();
    Object.assign(this, obj);
    this.isInCarousel = true;
  }
}

export class VerticalScreen extends SbtScreen {
  pid: string;
  meta?: ScreenMeta;
  carousels?: HorizontalScreen[];
  carouselAuto?: CarouselItem[];

  // Data from button
  picto?: string;
  intro?: string;

  constructor(obj: VerticalScreen) {
    super();
    Object.assign(this, obj);
    this.isInCarousel = false;
  }
}

export class ScreenMeta {
  title?: string;
  keyword?: string;
  description?: string;
}

export class ScreenLink {
  pid?: string;
  slug: string; // String slug from matching screen
  link: string; // String link from Twine
  text: string;
  type: ScreenLinkType;
  transition?: string;
  isExternal: boolean;

  constructor(obj: ScreenLink) {
    Object.assign(this, obj);
  }
}

export enum ScreenLinkType {
  empty = 0,
  square,
  disk,
  triangle,
  plus,
  times
}

export class Bookmark {
  slug: string;
  name: string;
  title: string;
  text: string;
  date: Date;
  background: string;

  constructor(obj: Bookmark) {
    Object.assign(this, obj);
  }
}

export class CarouselItem {
  id: string;
  text: string;
  links: ScreenLink[];
}




export class DataLinks {
  text: string;
  links: ScreenLink[];
}

