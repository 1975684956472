export class DomHelper {

  static checkDocumentAvailable(): boolean {
    return !(typeof document === 'undefined');
  }

  static isOverflownY(element: Element, inner: Element) {
    return (inner.clientHeight - 20) > element.clientHeight;
  }
}
