import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class EventService {

  private toast$: Subject<string> = new Subject();
  private menu$: Subject<boolean> = new Subject();
  private loading$: Subject<boolean> = new BehaviorSubject(true);

  constructor() {}

  public isLoading$() { return this.loading$.asObservable(); }
  public startLoading() { this.loading$.next(true); }
  public stopLoading() { this.loading$.next(false); }

  public getMenu$() { return this.menu$.asObservable(); }
  public openMenu() { this.menu$.next(true); }
  public closeMenu() { this.menu$.next(false); }

  public getToast$() {
    return this.toast$.asObservable();
  }

  public openToast(message: string) {
    this.toast$.next(message);
    setTimeout(() => { this.closeToast(); }, 3000);
  }

  public closeToast() {
    this.toast$.next(null);
  }
}
