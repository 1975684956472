import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Constants } from '../../constants';
import { AnimationService } from '../../services/animation.service';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-backgrounds',
  templateUrl: './backgrounds.component.html',
  styleUrls: ['./backgrounds.component.scss']
})
export class BackgroundsComponent implements OnInit, OnDestroy {

  private subBackground: Subscription;
  private subChangeImage: Subscription;

  public animateTop: boolean;
  public backgroundTop = Constants.VARIABLES.DEFAULT_BACKGROUND;
  public backgroundBottom: string;

  constructor(
    private animation: AnimationService,
    private logger: LoggerService,
  ) {}

  ngOnInit() {
    this.subBackground = this.animation.getBackground$().subscribe(transition => {
      this.backgroundBottom = `url('${transition.background}')`;
      timer(1600).subscribe(() => {
        this.animateTop = true;
        if (transition.automatic) {
          this.launchBackgroundAnimation();
        }
      });
    });
  }

  launchBackgroundAnimation() {
    if (this.subChangeImage) {
      this.subChangeImage.unsubscribe();
    }

    this.subChangeImage = timer(1500).subscribe(() => {
      this.backgroundTop = this.backgroundBottom;
      this.backgroundBottom = 'none';
      this.animateTop = false;
    });
  }

  ngOnDestroy() {
    if (this.subBackground) {
      this.subBackground.unsubscribe();
    }
  }
}
