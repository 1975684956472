const TAGS_NAME = {
  CAROUSEL: '<CARROUSEL>',
  ITEM: '<ITEM',
  CAROUSEL_AUTO: '<CARROUSEL_auto>',
  BACKGROUND_LANDSCAPE: '<background_l>',
  BACKGROUND_PORTRAIT: '<background_p>',
  META_TITLE: '<metaTitle>',
  META_KEYWORD: '<keyword>',
  META_DESCRIPTION: '<desc>',
  META_SLUG: '<slug>',
  TITLE: '<h1>',
};

export const Parses = {
  TAGS_NAME
};
