import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackgroundsComponent } from './components/backgrounds/backgrounds.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { HomeComponent } from './components/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuComponent } from './components/menu/menu.component';
import { OrientationComponent } from './components/orientation/orientation.component';
import { RulesComponent } from './components/rules/rules.component';
import { ScreenComponent } from './components/screen/screen.component';
import { ToastComponent } from './components/toast/toast.component';
import { AnimationService } from './services/animation.service';
import { DataService } from './services/data.service';
import { EventService } from './services/event.service';
import { HttpService } from './services/http.service';
import { LoggerService } from './services/logger.service';
import { SEOService } from './services/seo.service';
import { SessionService } from './services/session.service';
import { StorageService } from './services/storage.service';

// DO NOT import hammer here, for ssr compat
export class MyHammerConfig extends HammerGestureConfig {
  // Doc : https://hammerjs.github.io/api/
  overrides = {
    swipe: { direction: 30 } // All direction
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BookmarksComponent,
    ScreenComponent,
    BackgroundsComponent,
    ToastComponent,
    MenuComponent,
    RulesComponent,
    LoadingComponent,
    OrientationComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxUsefulSwiperModule,
    TranslateModule.forRoot({ loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }}),
  ],
  providers: [
    LoggerService,
    StorageService,
    HttpService,
    DataService,
    AnimationService,
    EventService,
    SessionService,
    SEOService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
