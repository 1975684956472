import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../../services/event.service';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  public message: string;

  constructor(
    private event: EventService,
    private logger: LoggerService,
  ) {}

  ngOnInit() {
    this.subscription = this.event.getToast$().subscribe(message => {
      this.message = message;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
